import Stats from "stats.js";
import Proton from "proton-engine/build/proton.min";
import RAFManager from "raf-manager";
import "./styles.css";
import * as dat from 'dat.gui';

let stats;
let canvas;
let context;
let proton;
let renderer;
let emitters;
let mouseObj;
let gui;
let props = {};

main();

function main() {
  createProps2();
  initCanvas();
  initStats();
  mouseObj = { // out of canvas
    x: canvas.width * 2,
    y: canvas.height * 2
  };
  createProton();
  createGUI();
  render();
}

function initCanvas() {
  canvas = document.createElement('canvas');
  canvas.id = 'canvasId';

  var elements = document.getElementsByClassName("particles-bg");
  var insertdiv = elements[0];
  insertdiv.appendChild(canvas);
  canvas.width = canvas.parentElement.clientWidth;
  canvas.height = canvas.parentElement.clientHeight;
  context = canvas.getContext("2d");

  window.onresize = function(e) {
    canvas.width = canvas.parentElement.clientWidth;
    canvas.height = canvas.parentElement.clientHeight;
    updateEmitterDifferences();
    updateBehaviours();
  };
}

function createProps() {
  props.preset = 1;
  props.background = true
  props.background_color = 'rgb(39, 38, 37)'

  props.line_y = 0.5;
  props.line_left = 0.2;
  props.line_right = 0.8;
  props.line_thickness = 7.5;
  props.line_color = "#ffffff";
  props.line_in_background = false;
  
  props.emitter_left = 0.25;
  props.emitter_right = 0.75;
  props.create_rate_min = 80;
  props.create_rate_max = 120;

  props.emitters = 4
  props.emitter1_angle_from = 40
  props.emitter1_angle_to = 80
  props.emitter2_angle_from = 80
  props.emitter2_angle_to = 140
  props.emitter3_angle_from = -40
  props.emitter3_angle_to = -80
  props.emitter4_angle_from = -80
  props.emitter4_angle_to = -140

  props.radius_min = 4.5;
  props.radius_max = 5.7;
  props.scale_start = 0.7;
  props.scale_end = 0.2;
  props.particle_color = "#ffffff";
  props.tail_joint_count = 200;
  props.tail_color = [ 63, 63, 63 ];
  props.tail_delay = 10;
  
  props.mass = 5.5;
  props.gravity = 0;
  props.life_min = 1.5;
  props.life_max = 4.6;
  props.velocity_min = 1.6;
  props.velocity_max = 1.6;

  props.drift = false;
  props.drift_x = 35;
  props.drift_y = 35;
  props.drift_delay = 0.3;
  
  props.attraction1 = true;
  props.attraction1_x = 0.5;
  props.attraction1_y = 0.5;
  props.attraction1_force = 2.75;
  props.attraction1_distance = 90;
  props.attraction2 = true;
  props.attraction2_x = 0.8;
  props.attraction2_y = 0.8;
  props.attraction2_force = 3;
  props.attraction2_distance = 988;

  props.mouse = true;
  props.mouse_repulsion = false;
  props.mouse_force = 18.5;
  props.mouse_distance = 310;
  props.mouse2 = false;
  props.mouse2_repulsion = false;
  props.mouse2_force = 25;
  props.mouse2_distance = 100;
}

function createProps2() {
  props.preset = 2;
  props.background = true
  props.background_color = 'rgb(39, 38, 37)'

  props.line_y = 0.63;
  props.line_left = 0.2;
  props.line_right = 0.8;
  props.line_thickness = 7.5;
  props.line_color = "#ffffff";
  props.line_in_background = false;
  
  props.emitter_left = 0.25;
  props.emitter_right = 0.75;
  props.create_rate_min = 40;
  props.create_rate_max = 60;

  props.emitters = 2
  props.emitter1_angle_from = 40
  props.emitter1_angle_to = 140
  props.emitter2_angle_from = -40
  props.emitter2_angle_to = -140
  props.emitter3_angle_from = 0
  props.emitter3_angle_to = 0
  props.emitter4_angle_from = 0
  props.emitter4_angle_to = 0

  props.radius_min = 5.5;
  props.radius_max = 7;
  props.scale_start = 0.7;
  props.scale_end = 0.2;
  props.particle_color = "#ffffff";
  props.tail_joint_count = 30;
  props.tail_color = [ 88, 88, 88 ];
  props.tail_delay = 10;

  props.mass = 5.5;
  props.gravity = 0;
  props.life_min = 1.5;
  props.life_max = 4.6;
  props.velocity_min = 1.6;
  props.velocity_max = 1.6;

  props.drift = false;
  props.drift_x = 35;
  props.drift_y = 35;
  props.drift_delay = 0.3;
  
  props.attraction1 = true;
  props.attraction1_x = 0.5;
  props.attraction1_y = 0.5;
  props.attraction1_force = 2.75;
  props.attraction1_distance = 90;
  props.attraction2 = true;
  props.attraction2_x = 0.8;
  props.attraction2_y = 0.8;
  props.attraction2_force = 3;
  props.attraction2_distance = 988;

  props.mouse = true;
  props.mouse_repulsion = false;
  props.mouse_force = 50;
  props.mouse_distance = 600;
  props.mouse2 = true;
  props.mouse2_repulsion = false;
  props.mouse2_force = 70;
  props.mouse2_distance = 200;
}

function createProps3() {
  props.preset = 3;
  props.background = true
  props.background_color = 'rgb(39, 38, 37)'

  props.line_y = 0.5;
  props.line_left = 0.2;
  props.line_right = 0.8;
  props.line_thickness = 7;
  props.line_color = "#ffffff";
  props.line_in_background = false;
  
  props.emitter_left = 0.25;
  props.emitter_right = 0.75;
  props.create_rate_min = 80;
  props.create_rate_max = 100;

  props.emitters = 2
  props.emitter1_angle_from = 40
  props.emitter1_angle_to = 140
  props.emitter2_angle_from = -40
  props.emitter2_angle_to = -140
  props.emitter3_angle_from = 0
  props.emitter3_angle_to = 0
  props.emitter4_angle_from = 0
  props.emitter4_angle_to = 0

  props.radius_min = 8;
  props.radius_max = 10;
  props.scale_start = 0.7;
  props.scale_end = 0.2;
  props.particle_color = "#ff0000";
  props.tail_joint_count = 100;
  props.tail_color = [ 78, 78, 78 ];
  props.tail_delay = 10;

  props.mass = 1.5;
  props.gravity = 0;
  props.life_min = 3;
  props.life_max = 4.5;
  props.velocity_min = 0.9;
  props.velocity_max = 1.9;

  props.drift = false;
  props.drift_x = 35;
  props.drift_y = 35;
  props.drift_delay = 0.3;
  
  props.attraction1 = true;
  props.attraction1_x = 0.2;
  props.attraction1_y = 0.5;
  props.attraction1_force = 0.75;
  props.attraction1_distance = 330;
  props.attraction2 = true;
  props.attraction2_x = 0.8;
  props.attraction2_y = 0.5;
  props.attraction2_force = 0.9;
  props.attraction2_distance = 480;

  props.mouse = true;
  props.mouse_repulsion = true;
  props.mouse_force = 16;
  props.mouse_distance = 100;

  props.mouse2 = true;
  props.mouse2_repulsion = true;
  props.mouse2_force = 3.5;
  props.mouse2_distance = 250;
}

function createGUI() {
  gui = new dat.GUI();

  gui.add(props, 'preset', { Sebi: 1, Emb: 2, Big: 3 } ).name('Preset').onChange(function(newValue) {
    
    if (newValue == 1) {
      createProps();
    }
    else if (newValue == 2) {
      createProps2();
    }
    else {
      createProps3();
    }
    gui.updateDisplay();
    
    stopEmit();
    updateInitializers();
    updateBehaviours();
    startEmit();
  });

  let folderBackground = gui.addFolder('Background');
  folderBackground.add(props, "background").name('Draw Background');
  folderBackground.addColor(props, "background_color").name('Color');

  let folderLine = gui.addFolder('Line');
  folderLine.add(props, "line_y").name('Left').min(0).max(1).step(0.01).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderLine.add(props, "line_left").name('Left').min(0).max(1).step(0.01);
  folderLine.add(props, "line_right").name('Right').min(0).max(1).step(0.01);
  folderLine.add(props, "line_thickness").name('Thickness').min(0).max(10).step(0.5);
  folderLine.addColor(props, "line_color").name('Color');
  folderLine.add(props, "line_in_background").name('In Background');

  let folderEmitter = gui.addFolder('Emitter');
  folderEmitter.add(props, "emitter_left").name('Left').min(0).max(1).step(0.01).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderEmitter.add(props, "emitter_right").name('Right').min(0).max(1).step(0.01).onChange(function(newValue) {
    updateEmitterDifferences();
  });

  folderEmitter.add(props, "create_rate_min").name('Create Rate (min)').min(0).max(200).step(0.1).onChange(function(newValue) {
    if (props.create_rate_max < props.create_rate_min) {
      props.create_rate_max = props.create_rate_min;
      gui.updateDisplay()
    }
    updateBehaviours();
  });

  folderEmitter.add(props, "create_rate_max").name('Create Rate (max)').min(0).max(200).step(0.1).onChange(function(newValue) {
    if (props.create_rate_max < props.create_rate_min) {
      props.create_rate_min = props.create_rate_max;
      gui.updateDisplay()
    }
    updateBehaviours();
  });

  folderEmitter.add(props, 'emitters', { One: 1, Two: 2, Four: 4 } ).name('Emitters').onChange(function(newValue) {
    
    if (newValue == 1) {
      props.emitter1_angle_from = -180
      props.emitter1_angle_to = 180
      props.emitter2_angle_from = 0
      props.emitter2_angle_to = 0
      props.emitter3_angle_from = 0
      props.emitter3_angle_to = 0
      props.emitter4_angle_from = 0
      props.emitter4_angle_to = 0
    }
    else if (newValue == 2) {
      props.emitter1_angle_from = 40
      props.emitter1_angle_to = 140
      props.emitter2_angle_from = -40
      props.emitter2_angle_to = -140
      props.emitter3_angle_from = 0
      props.emitter3_angle_to = 0
      props.emitter4_angle_from = 0
      props.emitter4_angle_to = 0
    }
    else {
      props.emitter1_angle_from = 40
      props.emitter1_angle_to = 80
      props.emitter2_angle_from = 80
      props.emitter2_angle_to = 140
      props.emitter3_angle_from = -40
      props.emitter3_angle_to = -80
      props.emitter4_angle_from = -80
      props.emitter4_angle_to = -140
    }
    gui.updateDisplay();
    
    stopEmit();
    updateEmitterDifferences();
    startEmit();
  });

  folderEmitter.add(props, "emitter1_angle_from").name('1. Angle from').min(-180).max(180).step(1).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderEmitter.add(props, "emitter1_angle_to").name('1. Angle to').min(-180).max(180).step(1).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderEmitter.add(props, "emitter2_angle_from").name('2. Angle from').min(-180).max(180).step(1).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderEmitter.add(props, "emitter2_angle_to").name('2. Angle to').min(-180).max(180).step(1).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderEmitter.add(props, "emitter3_angle_from").name('3. Angle from').min(-180).max(180).step(1).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderEmitter.add(props, "emitter3_angle_to").name('3. Angle to').min(-180).max(180).step(1).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderEmitter.add(props, "emitter4_angle_from").name('4. Angle from').min(-180).max(180).step(1).onChange(function(newValue) {
    updateEmitterDifferences();
  });
  folderEmitter.add(props, "emitter4_angle_to").name('4. Angle to').min(-180).max(180).step(1).onChange(function(newValue) {
    updateEmitterDifferences();
  });

  let folderParticle = gui.addFolder('Particle');
  folderParticle.add(props, "radius_min").name('Radius (min)').min(0).max(10).step(0.1).onChange(function(newValue) {
    if (props.radius_max < props.radius_min) {
      props.radius_max = props.radius_min;
      gui.updateDisplay()
    }
    updateInitializers();
  });

  folderParticle.add(props, "radius_max").name('Radius (max)').min(0).max(10).step(0.1).onChange(function(newValue) {
    if (props.radius_max < props.radius_min) {
      props.radius_min = props.radius_max;
      gui.updateDisplay()
    }
    updateInitializers();
  });

  folderParticle.add(props, "scale_start").name('Scale (start)').min(0).max(1).step(0.1).onChange(function(newValue) {
    updateBehaviours();
  });

  folderParticle.add(props, "scale_end").name('Scale (end)').min(0).max(1).step(0.1).onChange(function(newValue) {
    updateBehaviours();
  });

  folderParticle.addColor(props, "particle_color").name('Color').onChange(function(newValue) {
    updateBehaviours();
  });
  folderParticle.add(props, "tail_joint_count").name('Tail Length').min(0).max(300).step(1);
  folderParticle.addColor(props, "tail_color").name('Tail Color');
  folderParticle.add(props, "tail_delay").name('Tail Delay').min(5).max(30).step(1);

  let folderPhysics = gui.addFolder('Physics');
  folderPhysics.add(props, "mass").name('Mass').min(0).max(10).step(0.5).onChange(function(newValue) { 
    updateInitializers();
  });

  folderPhysics.add(props, "gravity").name('Gravity').min(0).max(5).step(0.1).onChange(function(newValue) { 
    updateBehaviours();
  });

  folderPhysics.add(props, "life_min").name('Life (min)').min(0).max(10).step(0.1).onChange(function(newValue) { 
    if (props.life_max < props.life_min) {
      props.life_max = props.life_min;
      gui.updateDisplay()
    }
    updateInitializers();
  });

  folderPhysics.add(props, "life_max").name('Life (max)').min(0).max(10).step(0.1).onChange(function(newValue) { 
    if (props.life_max < props.life_min) {
      props.life_min = props.life_max;
      gui.updateDisplay()
    }
    updateInitializers();
  });

  folderPhysics.add(props, "velocity_min").name('Velocity (min)').min(0).max(10).step(0.1).onChange(function(newValue) { 
    if (props.velocity_max < props.velocity_min) {
      props.velocity_max = props.velocity_min;
      gui.updateDisplay()
    }
    updateInitializers();
  });

  folderPhysics.add(props, "velocity_max").name('Velocity (max)').min(0).max(10).step(0.1).onChange(function(newValue) { 
    if (props.velocity_max < props.velocity_min) {
      props.velocity_min = props.velocity_max;
      gui.updateDisplay()
    }
    updateInitializers();
  });

  let folderDrift = folderPhysics.addFolder('Drift');
  folderDrift.add(props, "drift").name('Drift').onChange(function(newValue) {
    updateBehaviours();
  });  

  folderDrift.add(props, "drift_x").name('Drift X').min(0).max(50).step(1).onChange(function(newValue) {
    updateBehaviours();
  });

  folderDrift.add(props, "drift_y").name('Drift Y').min(0).max(50).step(1).onChange(function(newValue) {
    updateBehaviours();
  });

  folderDrift.add(props, "drift_delay").name('Drift Delay').min(0).max(5).step(0.1).onChange(function(newValue) {
    updateBehaviours();
  });

  let folderAttraction = folderPhysics.addFolder('Attraction');
  folderAttraction.add(props, "attraction1").name('1. Attraction').onChange(function(newValue) {
    updateBehaviours();
  });  

  folderAttraction.add(props, "attraction1_x").name('X').min(0).max(1).step(0.1).onChange(function(newValue) {
    updateBehaviours();
  });  

  folderAttraction.add(props, "attraction1_y").name('Y').min(0).max(1).step(0.1).onChange(function(newValue) {
    updateBehaviours();
  });  

  folderAttraction.add(props, "attraction1_force").name('Force').min(0).max(3).step(0.05).onChange(function(newValue) {
    updateBehaviours();
  });

  folderAttraction.add(props, "attraction1_distance").name('Distance').min(0).max(1000).step(1).onChange(function(newValue) {
    updateBehaviours();
  });

  folderAttraction.add(props, "attraction2").name('2. Attraction').onChange(function(newValue) {
    updateBehaviours();
  });  

  folderAttraction.add(props, "attraction2_x").name('X').min(0).max(1).step(0.1).onChange(function(newValue) {
    updateBehaviours();
  });  

  folderAttraction.add(props, "attraction2_y").name('Y').min(0).max(1).step(0.1).onChange(function(newValue) {
    updateBehaviours();
  });  

  folderAttraction.add(props, "attraction2_force").name('Force').min(0).max(3).step(0.05).onChange(function(newValue) {
    updateBehaviours();
  });

  folderAttraction.add(props, "attraction2_distance").name('Distance').min(0).max(1000).step(1).onChange(function(newValue) {
    updateBehaviours();
  });

  let folderInteraction = gui.addFolder('Interaction');
  folderInteraction.add(props, "mouse").name('1. Mouse').onChange(function(newValue) {
    updateBehaviours();
  });

  folderInteraction.add(props, "mouse_repulsion").name('Repulsion').onChange(function(newValue) {
    updateBehaviours();
  });

  folderInteraction.add(props, "mouse_force").name('Force').min(0).max(50).step(0.5).onChange(function(newValue) {
    updateBehaviours();
  });

  folderInteraction.add(props, "mouse_distance").name('Distance').min(0).max(1000).step(10).onChange(function(newValue) {
    updateBehaviours();
  });


  folderInteraction.add(props, "mouse2").name('2. Mouse').onChange(function(newValue) {
    updateBehaviours();
  });

  folderInteraction.add(props, "mouse2_repulsion").name('Repulsion').onChange(function(newValue) {
    updateBehaviours();
  });

  folderInteraction.add(props, "mouse2_force").name('Force').min(0).max(50).step(0.5).onChange(function(newValue) {
    updateBehaviours();
  });

  folderInteraction.add(props, "mouse2_distance").name('Distance').min(0).max(1000).step(10).onChange(function(newValue) {
    updateBehaviours();
  });

  var obj = { reset_all:function(){ 
    console.log("Reset All");
    if (props.preset == 1) {
      createProps();
    }
    else if (props.preset == 2) {
      createProps2();
    }
    else {
      createProps3();
    }
    gui.updateDisplay();
    stopEmit();
    updateInitializers();
    updateBehaviours();
    startEmit();
  }};
  gui.add(obj,'reset_all').name('Reset All');

}

function initStats() {
  stats = new Stats();
  stats.setMode(0);
  stats.domElement.style.position = "absolute";
  stats.domElement.style.left = "0px";
  stats.domElement.style.top = "0px";
  document.body.appendChild(stats.domElement);
}

function stopEmit() {
  for (let index = 0; index < 4; index++) {
    let emitter = emitters[index];
    emitter.stop()
  }  
}

function startEmit() {
  emitters[0].emit()
  if (props.emitters >= 2) {
    emitters[1].emit()
  }
  if (props.emitters == 4) {
    emitters[2].emit()
    emitters[3].emit()
  }
}

function updateInitializers()
{
  for (let index = 0; index < 4; index++) {
    let emitter = emitters[index];
    emitter.removeAllInitializers();
    emitter.addInitialize(new Proton.Mass(props.mass));
    emitter.addInitialize(new Proton.Radius(props.radius_min, props.radius_max));
    emitter.addInitialize(new Proton.Life(props.life_min, props.life_max));
  }  
  updateEmitterDifferences();
}

function updateBehaviours()
{
  let emitter_length = canvas.width * (props.emitter_right - props.emitter_left);

  for (let index = 0; index < 4; index++) {
    let emitter = emitters[index];

    emitter.removeAllBehaviours();

    emitter.rate = new Proton.Rate(new Proton.Span(1, 1), new Proton.Span(props.create_rate_min / emitter_length, props.create_rate_max / emitter_length));

    emitter.addBehaviour(new Proton.Scale(props.scale_start, props.scale_end));
    
    if (props.attraction1) {
      emitter.addBehaviour(new Proton.Attraction({x: props.attraction1_x * canvas.width, y: props.attraction1_y * canvas.height}, props.attraction1_force, props.attraction1_distance));
    }
    if (props.attraction2) {
      emitter.addBehaviour(new Proton.Attraction({x: props.attraction2_x * canvas.width, y: props.attraction2_y * canvas.height}, props.attraction2_force, props.attraction2_distance));
    }

    if (props.mouse) {
      if (props.mouse_repulsion) {
        emitter.addBehaviour(new Proton.Repulsion(mouseObj, props.mouse_force, props.mouse_distance));
      } else
      {
        emitter.addBehaviour(new Proton.Attraction(mouseObj, props.mouse_force, props.mouse_distance));
      }
    }

    if (props.mouse2) {
      if (props.mouse2_repulsion) {
        emitter.addBehaviour(new Proton.Repulsion(mouseObj, props.mouse2_force, props.mouse2_distance));
      } else
      {
        emitter.addBehaviour(new Proton.Attraction(mouseObj, props.mouse2_force, props.mouse2_distance));
      }
    }

    emitter.addBehaviour(new Proton.Gravity(props.gravity));

    if (props.drift) {
      emitter.addBehaviour(new Proton.RandomDrift(props.drift_x,props.drift_y, props.drift_delay));
    }

    emitter.addBehaviour(new Proton.Color(props.particle_color));
    emitter.addBehaviour(new Proton.Alpha(1, 0));
  }  
}

function updateEmitterDifferences() {

  var lineZoneLeft = new Proton.LineZone(canvas.width * props.emitter_left, canvas.height * props.line_y, canvas.width * ((props.emitter_left + props.emitter_right) / 2 + 0.05), canvas.height * props.line_y);
  var lineZoneRight = new Proton.LineZone(canvas.width * ((props.emitter_left + props.emitter_right) / 2 - 0.05), canvas.height * props.line_y, canvas.width * props.emitter_right, canvas.height * props.line_y);
  var lineZone = new Proton.LineZone(canvas.width * props.emitter_left, canvas.height * props.line_y, canvas.width * props.emitter_right, canvas.height * props.line_y);
  if (props.emitters == 4) {

    emitters[0].addInitialize(
      new Proton.P(
        lineZoneLeft
      )
    );

    emitters[1].addInitialize(
      new Proton.P(
        lineZoneRight
      )
    );

    emitters[2].addInitialize(
      new Proton.P(
        lineZoneLeft
      )
    );

    emitters[3].addInitialize(
      new Proton.P(
        lineZoneRight
      )
    );

  } else {

    emitters[0].addInitialize(
      new Proton.P(
        lineZone
      )
    );

    emitters[1].addInitialize(
      new Proton.P(
        lineZone
      )
    );

    emitters[2].addInitialize(
      new Proton.P(
        lineZone
      )
    );

    emitters[3].addInitialize(
      new Proton.P(
        lineZone
      )
    )
  }

  emitters[0].rotation = 90;
  emitters[0].addInitialize(
    new Proton.Velocity(
      new Proton.Span(props.velocity_min, props.velocity_max),
      new Proton.Span(props.emitter1_angle_from, props.emitter1_angle_to, false),
      'polar'
    )
  );

  emitters[1].rotation = 90;
  emitters[1].addInitialize(
    new Proton.Velocity(
      new Proton.Span(props.velocity_min, props.velocity_max),
      new Proton.Span(props.emitter2_angle_from, props.emitter2_angle_to, false),
      'polar'
    )
  );

  emitters[2].rotation = 90;
  emitters[2].addInitialize(
    new Proton.Velocity(
      new Proton.Span(props.velocity_min, props.velocity_max),
      new Proton.Span(props.emitter3_angle_from, props.emitter3_angle_to, false),
      'polar'
    )
  );

  emitters[3].rotation = 90;
  emitters[3].addInitialize(
    new Proton.Velocity(
      new Proton.Span(props.velocity_min, props.velocity_max),
      new Proton.Span(props.emitter4_angle_from, props.emitter4_angle_to, false),
      'polar'
    )
  );
}


function createEmitters() {
  emitters = [];

  for (let index = 0; index < 4; index++) {
    emitters[index] = new Proton.Emitter();
  }

  updateInitializers();
  updateBehaviours();
  startEmit()

  for (let index = 0; index < 4; index++) {
    proton.addEmitter(emitters[index]);
  }
}

function drawLine() {
  context.beginPath();
  context.strokeStyle = props.line_color;
  context.moveTo(canvas.width * props.line_left, canvas.height * props.line_y);
  context.lineTo(canvas.width * props.line_right, canvas.height * props.line_y);
  context.lineWidth = props.line_thickness;
  context.lineCap = 'round';
  context.stroke();
  context.closePath();
}

function createProton() {
  proton = new Proton();
  proton.fps = 100
  createEmitters();
  renderer = new Proton.CanvasRenderer(canvas);

  canvas.addEventListener('mousemove', mousemoveHandler, false);

  renderer.onProtonUpdate = () => {
    context.clearRect(0, 0, canvas.width, canvas.height);
    if (props.background) {
      context.rect(0, 0, canvas.width, canvas.height);
      context.fillStyle = props.background_color;
      context.fill();
    }

    if (props.line_in_background) {
      drawLine();
    }
  };

  renderer.onProtonUpdateAfter = () => {
    if (!props.line_in_background) {
      drawLine();
    }
  };

  renderer.onParticleCreated = (particle) => {
    particle.data.points = [];
    particle.data.index = 0;
  };

  renderer.onParticleUpdate = (particle) => {
    drawTadpoleTail(particle);
    if (particle.data.index % props.tail_delay === 0) fillPointsData(particle);
    drawTadpoleHead(particle);
    particle.data.index++;
  };

  const fillPointsData = (particle) => {
    particle.data.points.unshift(particle.p.y);
    particle.data.points.unshift(particle.p.x);
    if (particle.data.points.length > props.tail_joint_count) {
      particle.data.points.pop();
      particle.data.points.pop();
    }
  };

  const drawTadpoleHead = (particle) => {
    context.fillStyle = `rgba(${particle.rgb.r},${particle.rgb.g},${particle.rgb.b},${particle.alpha})`;
    context.beginPath();
    context.arc(
      particle.p.x,
      particle.p.y,
      particle.radius,
      0,
      Math.PI * 2,
      true
    );
    context.closePath();
    context.fill();
  };

  const drawTadpoleTail = (particle) => {
    context.beginPath();
    context.strokeStyle = particle.color;
    context.moveTo(particle.p.x, particle.p.y);
    const l = particle.data.points.length;
    for (let i = 0; i < l; i += 2) {
      const x = particle.data.points[i];
      const y = particle.data.points[i + 1];

      context.lineWidth = 0.7 * particle.scale;
      context.strokeStyle = `rgba(${props.tail_color[0]},${props.tail_color[1]},${props.tail_color[2]},${particle.alpha})`;
      context.lineTo(x, y);
      context.lineCap = 'round';
      context.stroke();
    }
    context.closePath();
  };

  proton.addRenderer(renderer);
}

function render() {
  RAFManager.add(() => {
    stats.begin();
    proton.update();
    stats.end();
  });
}

function mousemoveHandler(e) {
  if (e.layerX || e.layerX == 0) {
      mouseObj.x += (e.layerX - mouseObj.x) * .5;
      mouseObj.y += (e.layerY - mouseObj.y) * .5;
  } else if (e.offsetX || e.offsetX == 0) {
      mouseObj.x += (e.offsetX - mouseObj.x) * .5;
      mouseObj.y += (e.offsetY - mouseObj.y) * .5;
  }
};

